import { addHours } from 'date-fns';
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { Cache } from 'services';
import asyncPool from 'utils/async-pool';

export default function useSharepointAPI(props) {
  const { Graph } = props;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();

  async function getAllFolderItemsInternal(
    itemId,
    driveId,
    signal,
    onProgress,
    fileCount = { total: 0, current: 0 },
  ) {
    const items = await getFolderItems(
      driveId,
      itemId,
      signal,
      onProgress,
      fileCount,
    );
    return items;
  }

  function getFoldersFromItems(items) {
    return items.filter((i) => i.folder);
  }
  function getFilesFromItems(items) {
    return items.filter((i) => !i.folder).map((i) => i);
  }

  async function getAllFolderItems(itemId, driveId, signal, onProgress) {
    return getAllFolderItemsInternal(itemId, driveId, signal, onProgress);
  }

  async function getAllFolderItemsNested(
    itemId,
    driveId,
    signal,
    onProgress,
    fileCount = { total: 0, current: 0 },
  ) {
    const items = await getAllFolderItemsInternal(
      itemId,
      driveId,
      signal,
      onProgress,
      fileCount,
    );
    fileCount = {
      total: fileCount?.total || 0 + items.length,
    };
    const results = [];
    try {
      await asyncPool(2, items, async (item) => {
        if (item.folder) {
          const folderItems = await getAllFolderItemsNested(
            item.id,
            driveId,
            signal,
            onProgress,
            fileCount,
          );
          folderItems.forEach((file) => results.push(file));
        }
        results.push(item);
      });
    } catch (e) {
      console.error(e);
    }

    return results;
  }

  async function getRecentFiles(driveId = undefined) {
    const data = await Graph.request('/me/drive/recent', {}, 'Sites.Read.All');
    return data?.value;
  }
  async function getOnedrive() {
    const data = await Graph.request(`/me/drive`, {}, 'Sites.Read.All');
    return data;
  }
  async function getSite(siteId) {
    const data = await Graph.request(`/sites/${siteId}`, {}, 'Sites.Read.All');
    return data;
  }
  async function getSites(withDetails, onProgress, query = '') {
    const data = await Graph.request(
      `/sites?search=${query}`,
      {},
      'Sites.Read.All',
    );
    const newSites = data?.value;

    if (withDetails) {
      if (!query && Cache.getData('microsoft_sites')) {
        return Cache.getData('microsoft_sites').data;
      }
      if (onProgress) {
        onProgress(0, newSites?.length);
      }
      const sitesWithDetails = [];
      await Promise.all(
        newSites.map(async (site, key) => {
          try {
            site.drives = await getDrivesFromSite(site.id);
            site.type = !site.drives?.filter?.((drive) =>
              drive.name?.includes('Teams Wiki'),
            )?.length
              ? 'sharepoint'
              : 'teams';
            sitesWithDetails.push(site);
          } catch (e) {
            console.error(e);
          }
          if (onProgress) {
            onProgress(key + 1, newSites?.length, site);
          }
        }),
      );
      if (!query) {
        Cache.setData({
          data: sitesWithDetails,
          expires: addHours(new Date(), 1),
          id: 'microsoft_sites',
        });
      }
      return sitesWithDetails;
    }
    return data?.value;
  }
  async function getAllDrives() {
    return getSites(true);
  }

  async function getGroups() {
    async function getResponse(top = 999, nextUrl = undefined) {
      return Graph.request(
        nextUrl || `/groups?$top=${top}`,
        {},
        'Group.Read.All',
      );
    }

    const values = [];
    let allGroupsReceived = false;
    let nextUrl;
    while (!allGroupsReceived) {
      const msResponse = await getResponse(999, nextUrl);
      values.push(...msResponse.value);
      allGroupsReceived = msResponse['@odata.nextLink'] === undefined;
      nextUrl = msResponse['@odata.nextLink'];
    }

    return values;
  }

  async function getDrive(driveId) {
    const data = await Graph.request(
      `/drives/${driveId}`,
      {},
      'Sites.Read.All',
    );
    return data;
  }
  async function getDriveItems(itemId, driveId) {
    const data = await Graph.request(
      `/drives/${driveId}/root/children`,
      {},
      'Sites.Read.All',
    );
    return data?.value;
  }
  async function getDriveItem(itemId, driveId) {
    const data = await Graph.request(
      `/drives/${driveId}/items/${itemId}`,
      {},
      'Sites.Read.All',
    );
    return data;
  }

  async function getFolder(itemId, driveId) {
    return getDriveItem(itemId, driveId);
  }

  async function getFolderItemsNext(
    link,
    signal,
    onProgress,
    fileCount = { total: 0, current: 0 },
  ) {
    onProgress({
      total: fileCount.total,
    });
    const moreItems = await Graph.request(link, {}, 'Sites.Read.All');
    if (signal.aborted) {
      return [];
    }
    if (moreItems?.['@odata.nextLink']) {
      const evenMore = await getFolderItemsNext(
        moreItems?.['@odata.nextLink'],
        signal,
        onProgress,
        { total: fileCount.total + moreItems.value.length },
      );
      return [...moreItems.value, ...evenMore];
    }
    return moreItems.value;
  }

  async function getFolderItems(
    driveId,
    folderId,
    signal,
    onProgress,
    fileCount,
  ) {
    const data = await Graph.request(
      `/drives/${driveId}/items/${folderId}/children`,
      {},
      'Sites.Read.All',
    );
    if (data?.['@odata.nextLink']) {
      const moreItems = await getFolderItemsNext(
        data?.['@odata.nextLink'],
        signal,
        onProgress,
        fileCount,
      );
      data.value = [...data.value, ...moreItems];
    }
    return data?.value;
  }

  async function getDrivesFromSite(siteId) {
    const data = await Graph.request(
      `/sites/${siteId}/drives`,
      {},
      'Sites.Read.All',
    );
    return data?.value;
  }

  async function deleteItem(driveId, itemId) {
    try {
      const data = await Graph.request(
        `/drives/${driveId}/items/${itemId}`,
        {
          method: 'DELETE',
        },
        'Sites.ReadWrite.All',
      );
      return data;
    } catch (e) {
      return true;
    }
  }

  async function renameItem(driveId, itemId, newName) {
    const data = await Graph.request(
      `/drives/${driveId}/items/${itemId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: newName,
        }),
      },
      'Sites.ReadWrite.All',
    );
    return data;
  }
  async function createFolder(driveId, itemId, folderName) {
    const data = await Graph.request(
      `/drives/${driveId}/items/${itemId}/children`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: folderName,
          folder: {},
        }),
      },
      'Sites.ReadWrite.All',
    );
    return data;
  }
  async function uploadFile(
    driveId,
    itemId,
    fileName,
    fileUrl,
    onProgress,
    onComplete,
  ) {
    const response = await fetch(fileUrl);
    if (!response.ok || !response.body) {
      throw new Error('Failed to fetch file stream');
    }

    const ext = fileName?.split?.('.')?.pop();
    const sanitizedFileName = `${
      fileName
        ?.split('.')[0]
        ?.replace(/[^\p{L}\p{N}._\-\s]/gu, '') // Removes unwanted characters
        ?.split(/\s+/) // Splits on one or more spaces
        .join('') // Joins segments without spaces
    }.${ext}`;
    const fileSize = parseInt(response.headers.get('Content-Length'), 10);
    const contentType =
      response.headers.get('Content-Type') || 'application/octet-stream';

    if (fileSize < 250 * 1024 * 1024) {
      // Direct PUT for small files < 250MB
      const fileData = await response.arrayBuffer();

      const uploadResponse = await Graph.request(
        `/drives/${driveId}/items/${itemId}:/${sanitizedFileName}:/content`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': contentType, // file MIME type for direct PUT
            'Content-Length': fileSize,
          },
          body: fileData,
        },
        'Sites.ReadWrite.All',
      );

      return uploadResponse;
    } // Chunked upload for files 250MB and above
    const data = await Graph.request(
      `/drives/${driveId}/items/${itemId}:/${sanitizedFileName}:/createUploadSession`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // JSON for session metadata
        },
        body: JSON.stringify({
          item: {
            '@microsoft.graph.conflictBehavior': 'replace',
            name: sanitizedFileName,
          },
          deferCommit: true,
        }),
      },
      'Sites.ReadWrite.All',
    );

    if (!data.uploadUrl) {
      throw new Error('Failed to create upload session');
    }

    const chunkSize = 20000000; // 20MB
    const reader = response.body.getReader();
    let start = 0;
    let buffer = new Uint8Array();

    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const tempBuffer = new Uint8Array(buffer.length + value.length);
        tempBuffer.set(buffer);
        tempBuffer.set(value, buffer.length);
        buffer = tempBuffer;

        while (buffer.length >= chunkSize) {
          const chunk = buffer.slice(0, chunkSize);
          buffer = buffer.slice(chunkSize);

          onProgress(start, fileSize);
          const uploadResponse = await Graph.request(
            data.uploadUrl,
            {
              method: 'PUT',
              headers: {
                'Content-Range': `bytes ${start}-${
                  start + chunk.length - 1
                }/${fileSize}`,
                'Content-Type': contentType, // file MIME type for chunked PUT
              },
              body: chunk,
              returnResponse: true,
            },
            'Sites.ReadWrite.All',
          );

          if (!uploadResponse.ok) {
            throw new Error(
              `Failed to upload chunk starting at ${start}, status ${uploadResponse.status}`,
            );
          }

          start += chunk.length;
        }
      }

      // Upload remaining buffer
      if (buffer.length > 0) {
        const finalUploadResponse = await Graph.request(
          data.uploadUrl,
          {
            method: 'PUT',
            headers: {
              'Content-Range': `bytes ${start}-${
                start + buffer.length - 1
              }/${fileSize}`,
              'Content-Type': contentType, // MIME type
            },
            returnResponse: true,
            body: buffer,
          },
          'Sites.ReadWrite.All',
        );

        if (!finalUploadResponse.ok) {
          throw new Error('Failed to upload final chunk');
        }
      }

      // Finalize the upload
      const finalizeResponse = await Graph.request(
        data.uploadUrl,
        { method: 'POST', returnResponse: true },
        'Sites.ReadWrite.All',
      );
      onComplete();

      if (!finalizeResponse.ok) {
        const responseText = await finalizeResponse.text();
        console.error(
          'Finalize upload failed:',
          finalizeResponse.status,
          responseText,
        );
        throw new Error(
          `Failed to finalize upload, status ${finalizeResponse.status}`,
        );
      }

      return finalizeResponse.json();
    } catch (error) {
      console.error('Upload error, deleting session:', error);
      await Graph.request(
        data.uploadUrl,
        { method: 'DELETE' },
        'Sites.ReadWrite.All',
      );
      throw new Error('Upload failed and session was deleted');
    }
  }

  async function replaceFile(driveId, itemId, fileContent) {
    const data = await Graph.request(
      `/drives/${driveId}/items/${itemId}/content`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: fileContent,
      },
      'Sites.ReadWrite.All',
    );
    return data;
  }
  async function search(query, type, params) {
    if (type === 'sites') {
      return await getSites(true, null, query);
    }
    if (type === 'files') {
      const data = await Graph.request(
        `/search/query`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            requests: [
              {
                entityTypes: ['driveItem'],
                query: {
                  queryString: query,
                },
                ...(params || {}),
              },
            ],
          }),
        },
        'Sites.Read.All',
      );
      return data?.value?.[0]?.hitsContainers?.[0];
    }
  }
  async function searchSites(query) {
    return search(query, 'sites', undefined);
  }
  async function shareItem(driveId, itemId, scope) {
    try {
      const data = await Graph.request(
        `/drives/${driveId}/items/${itemId}/createLink`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ type: 'view', scope: scope || 'anonymous' }),
        },
        'Sites.ReadWrite.All',
      );
      return data;
    } catch (e) {
      throw e;
    }
  }

  const translations = {
    FETCHING_DRIVE: 'Fetching drive from Sharepoint',
    FETCHING_ALL_FILES: 'Fetching all files from Sharepoint',
    CHECKING_ALREADY_IMPORTED: 'Checking for any file changes',
    REPLACING_UPDATED_FILES: 'Replacing changed files',
    CONNECTED: 'Starting upload',
    UPLOADING: 'Uploading file',
    PROCESSING: 'Processing file',
    AI: 'Processing file (AI)',
    JOB_CHECKED: 'All files imported',
    IMPORTING_FILES: 'Uploading files',
    ADDING_FILES_TO_COLLECTION: 'Adding already uploaded files to collection',
    ERROR_COLLECTION_DELETED: 'Sync failed. Collection has been deleted.',
    SOMETHING_WENT_WRONG: 'Sync failed due to an unknown reason.',
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }

  function createItemPath(item, currentPath = [], items) {
    if (item?.parentReference?.id) {
      const parent = items.find((i) => i.id === item?.parentReference?.id);
      if (parent) {
        item.path = [parent?.id, ...currentPath];
        if (
          parent?.parentReference &&
          items.find((i) => i.id === parent?.parentReference?.id)
        ) {
          return createItemPath(parent, item.path, items);
        }
      } else {
        item.path = currentPath;
      }
    }
    return item.path.join('/');
  }

  function getValue(key, item, preferences) {
    switch (key) {
      case 'id':
        return item?.id;
      case 'driveId':
        return (
          item?.parentReference?.driveId ??
          item?.remoteItem?.parentReference?.driveId
        );
      case 'idPath':
        return createItemPath(item, [], preferences?.items);
      case 'path':
        const path = `${item?.parentReference?.path?.split('/root:/')?.pop()}`;
        if (item.folder) {
          return `${path}/${item.name}`;
        }
        return path;
      case 'isFolder':
        return item?.folder;
      case 'isFolderEmpty':
        return item?.folder.childCount === 0;
      case 'folderParentReferenceId':
        return item?.parentReference?.id;
      case 'name':
        return item?.displayName ?? item?.name;
      case 'lastModified':
        return item?.fileSystemInfo?.lastModifiedDateTime;
      case 'createdBy':
        return item.createdBy?.user?.displayName;
      case 'size':
        return item?.size;
      case 'hash':
        return (
          item?.file?.hashes?.quickXorHash ??
          item?.file?.hashes?.sha1Hash ??
          item?.file?.hashes?.crc32Hash
        );
      case 'extension':
        return item?.name?.split?.('.')?.pop();
      case 'downloadUrl':
        return item?.['@microsoft.graph.downloadUrl'];
      case 'openUrl':
        return item.webUrl;
      case 'pathString':
        return decodeURIComponent(item.webUrl)
          ?.split('sharepoint.com/sites/')
          ?.pop();
      default:
        throw new Error(
          'failed getting api item value due to invalid or missing key',
        );
    }
  }

  function getSiteValue(key, item) {
    return getValue(key, item);
  }
  function getDriveValue(key, item) {
    return getValue(key, item);
  }

  async function processFileForImport(file, job, onError) {
    file = await getDriveItem(
      file.id,
      job?.driveId || getValue('driveId', file),
    );
    let downloadUrl = file['@microsoft.graph.downloadUrl'];
    if (job?.preferences?.externalFiles) {
      downloadUrl = file.webUrl;
      if (job?.preferences?.externalSeamlessDownload) {
        try {
          const perms = await shareItem(
            file?.parentReference?.driveId,
            file.id,
            job?.preferences?.externalSeamlessDownloadType,
          );
          downloadUrl = `${perms.link.webUrl}?download=1`;
          file.webUrl = perms.link.webUrl;
        } catch (e) {
          console.error(e);
          return onError(file, {
            type: 'ERROR',
            code: 'LINK_FAILED_GENERATING',
            isLoading: false,
          });
        }
      } else {
        try {
          const perms = await shareItem(
            file?.parentReference?.driveId,
            file.id,
            'organization',
          );
          downloadUrl = `${perms.link.webUrl}`;
        } catch (e) {
          console.error(e);

          return onError(file, {
            type: 'ERROR',
            code: 'LINK_FAILED_GENERATING',
            isLoading: false,
          });
        }
      }
    }

    return {
      accessUrl: downloadUrl,

      file: {
        name: file.name,
        file: {
          url: downloadUrl,
          open_url:
            job?.preferences?.externalFiles &&
            job?.preferences?.externalSeamlessDownload
              ? file.webUrl
              : undefined,
          ext: file.name.split('.').pop()?.toLowerCase(),
          external_file: job?.preferences?.externalFiles,
          name: file.name,
        },
        import: {
          from: props.API_ID,
          reference: file.id,
          referenceLocation: file?.parentReference?.driveId,
          source: job?._id,
          checksum: file?.file?.hashes?.quickXorHash,
        },
        uploadedAt: file.fileSystemInfo.lastModifiedDateTime,
        collections: job?.libraryCollection
          ? [job.libraryCollection]
          : undefined,
        .../* job?.ownerOverrideRequireApproval ?? */ (job?.requireApproval
          ? {
              approval: {
                status: 'none',
                status_change_reason: 'imported',
              },
            }
          : {}),
      },
      params: {
        ...getImportParams(file, job),
      },
    };
  }
  const getImportParams = (file, job) => {
    return {
      external_file: job?.preferences?.externalFiles,
      thumbnail_from: file['@microsoft.graph.downloadUrl'],
      thumbnail_from_ext: file.name.split('.').pop(),
    };
  };

  return {
    getRecentFiles,
    getSites,
    getSite,
    getDrivesFromSite,
    getDriveItems,
    getFolderItems,
    getFolder,
    getDriveItem,
    getDrive,
    getGroups,
    getOnedrive,
    getAllFolderItems,
    getAllFolderItemsNested,

    getFoldersFromItems,
    getFilesFromItems,
    search,
    searchSites,
    getAllDrives,
    getImportParams,

    createFolder,
    deleteItem,
    renameItem,
    uploadFile,
    replaceFile,

    translateCode,

    isConnected: Graph.isConnected,

    importedFiles: [
      ...(Media.data.imported_refs_microsoft || []),
      ...(Documents.data.imported_refs_microsoft || []),
      ...(Media.data.imported_refs_sharepoint || []),
      ...(Documents.data.imported_refs_sharepoint || []),
    ],

    shareItem,
    getValue,
    processFileForImport,
    getSiteValue,
    getDriveValue,
  };
}
