import {
  Button,
  Dropdown,
  Flex,
  Icon,
  Popup,
  Input,
  Switch,
  Choice,
  Tooltip,
  Confirm,
} from 'elements_v2';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDocumentsContext, useMediaContext, useUserContext } from 'hooks';
import { SortableV2 } from 'components/SortableV2';
import { useBoards } from 'contexts/Providers/BoardsProvider';
import { FileThumbnailV2 } from 'components/FileThumbnail';
import File from 'components/ContributionsGridV2/File';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { downloadUrl, openInNewTab, openLink, randomString } from 'utils';
import * as yup from 'yup';
import DocumentCollection from 'elements/DocumentCollection';
import useUserStatus from 'hooks/useUserStatus';
import { AssetToolbar } from '@pixi/components/AssetActions';
import BoardCard from 'components/BoardCard';
import FilesWithHook from 'views/Files/parts/FilesWithHook';
import { analytics } from 'services';
import useRouterQuery from 'hooks/useRouterQuery';
import AddRichText from '../RichText';
import { AddCollection } from './helpers/AddCollection';
import { AddBoard } from './helpers/AddBoard';
import { Link } from './Link';
import AddCustomLink from './helpers/AddCustomLink';
import { ModuleMetadataInputGroup } from '../../modules/ModuleMetadata';
import AddEmail from './helpers/AddEmail';
import { ColorItem } from './Color';
import TermsConfirm from './helpers/TermsConfirm';
import {
  openAssetPreview,
  openCollectionPreview,
  selectFile,
} from '@pixi/AppController';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import { ActionIcon, Group, Image, TextInput, ThemeIcon } from '@mantine/core';
import PixiForm from '@pixi/elements/Form';
import LinkForm from './helpers/LinkForm';
import { isEmbed } from 'utils/platform';

export const ContentSectionData = {
  contentSection: 'CONTENT',
};
export const ContentSectionMetadataProperties = {
  contentSection: yup.object().shape({
    // colorInfo: yup.mixed().oneOf(['outside', 'overlay']),
    contentPerRow: yup.number(),
    contentNoSpace: yup.boolean(),
    assetDisplayAs: yup.string(),
    assetImageScale: yup.string(),
    assetVideoAutoPlay: yup.boolean(),
    colorInfo: yup.mixed().oneOf(['outside', 'overlay']),
    colorInfoDisplay: yup.array(['hex', 'rgb', 'pms', 'cmyk']),
    colorAlignment: yup.mixed().oneOf(['fill', 'top', 'center', 'bottom']),
    colorThumbnail: yup.mixed().oneOf(['rectangle', 'square', 'circle']),
    withPadding: yup.boolean(),
  }),
};

function youtubeUrlToEmbedUrl(url) {
  // Extract the video ID using a regular expression
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length == 11) {
    // Construct the embed URL
    return `https://www.youtube.com/embed/${match[2]}`;
  }
  // Return null or an error message if the URL is invalid
  return null;
}
function extractUrlFromIframe(iframeHtml) {
  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string
  const doc = parser.parseFromString(iframeHtml, 'text/html');

  // Extract the src attribute from the first iframe element
  const iframe = doc.querySelector('iframe');
  return iframe ? iframe.src : null;
}
export function ContentSectionMetadata({
  Module,
  error,
  columns,
  disable,
  injectRight,
  onChange,
  metadata,
}) {
  return (
    <>
      {(!disable?.includes('contentNoSpace') ||
        !disable?.includes('contentPerRow')) && (
        <ModuleMetadataInputGroup label="Content">
          {!disable?.includes('contentNoSpace') && (
            <Input.Field
              label="No space between content"
              error={error?.path === 'contentSection.content' && error?.message}
              custom={
                <Switch
                  active={metadata?.contentSection?.contentNoSpace}
                  label="Enabled"
                  onChange={(value) =>
                    onChange({
                      contentSection: {
                        ...(metadata?.contentSection || {}),
                        contentNoSpace: value,
                      },
                    })
                  }
                />
              }
            />
          )}
          {!disable?.includes('contentPerRow') && (
            <Input.Field
              label="Columns"
              error={
                error?.path === 'contentSection.contentPerRow' && error?.message
              }
              style={{ maxWidth: 'none' }}
              custom={
                <Choice
                  value={metadata?.contentSection?.contentPerRow || 2}
                  onChange={(value) =>
                    onChange({
                      contentSection: {
                        ...(metadata?.contentSection || {}),
                        contentPerRow: value,
                      },
                    })
                  }
                  options={new Array(columns).fill(1).map((_, key) => ({
                    key: key + 1,
                    value: key + 1,
                    label: `${key + 1} column`,
                  }))}
                />
              }
            />
          )}
          {injectRight}
        </ModuleMetadataInputGroup>
      )}
    </>
  );
}

function ContentItem({
  data,
  allContentBlocks,
  Board,
  isRearrange,
  Module,
  onChange,
  focus,
  assetFocus,
  assetLabel,
  disable,
  setIsRearrange,
  textEditorPrefs,
  alignButtons,
  defaultText,
  disableDelete,
  buttonBackgroundColor,
  multipleAssets,
  forceReplaceAsset,
  onChangeMultiple,
  boardPreferences,
  onRemove,
  isOnlyOne,
  white,
}) {
  const Libraries = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brandassets: useMediaContext(),
  };
  const [selectedFilePreferences, setSelectedFilePreferences] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  const [selectedCollection, setSelectedCollection] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  if (!focus) {
    focus = [];
  }
  if (!disable) {
    disable = [];
  }
  const [isRichTextVisible, setIsRichTextVisible] = useState(false);
  const { generateUrl } = useStorageUrl();
  const [newLink, setNewLink] = useState({});
  const Boards = useBoards();
  const richTextRef = useRef();
  const blockRef = useRef();
  const User = useUserContext();
  const userStatus = useUserStatus();
  const [atBottomListener, setAtBottomListener] = useReducer(
    (state, action) => action,
    null,
  );
  const [iframeUrl, setIframeUrl] = useState('');

  const metadata = Module?.metadata?.contentSection || {};

  const location = useRouterQuery();
  useEffect(() => {
    if (
      Module.module?._id &&
      location.params.file &&
      location.params.section === Module.module?._id
    ) {
      const file =
        data.assets?.find((f) => f.value === location.params.file) ||
        data.links?.find((f) => f.value === location.params.file);
      if (file) {
        const fileData = Libraries[file.library].data.files.find(
          (f) => f._id === location.params.file,
        );
        // Module.onFileShare();
        openAssetPreview(fileData, {
          onClose: () => {
            location.set('file', '');
          },
        });
      }
    }
  }, [Module.data, location.params.file]);

  useEffect(() => {
    if (data?.text?.length || data?.textFile?.value) {
      setIsRichTextVisible(true);
    } else {
      setIsRichTextVisible(false);
    }
  }, [data?.text]);

  function populateLinks(link) {
    let data;
    if (link?.type === 'file') {
      link.library = link.library === 'brandassets' ? 'media' : link.library;
      data = Libraries[link.library]?.data?.files?.find(
        (file) => file._id === link.value,
      );
    }
    if (link.type === 'collection') {
      data = Libraries?.[link.library]?.data.collections?.find(
        (col) => col._id === link.value,
      );
    }
    if (link.type === 'board') {
      data = Boards.data.find((board) => board._id === link.value);
    }
    return {
      data,
      ...link,
    };
  }

  const allBlockAssets = allContentBlocks.reduce(
    (all, block) => [...all, ...(block.assets || [])],
    [],
  );

  const links = data?.links?.filter((i) => !!i).map(populateLinks);
  const downloads = data?.downloads?.filter((i) => !!i).map(populateLinks);
  const assetThumb = useRef();

  const addBoard = (list, board) => {
    onChange({
      ...(data || {}),
      [list]: [
        ...(data?.[list] || []),
        {
          type: 'board',
          value: board._id,
          label: board.name,
        },
      ],
    });
    setNewLink(board._id);
  };

  const addCollection = (list, collection, library) => {
    onChange({
      ...(data || {}),
      [list]: [
        ...(data?.[list] || []),
        {
          type: 'collection',
          library,
          value: collection._id,
          label: collection.name,
        },
      ],
    });
    setNewLink({ list, id: collection._id });
  };

  const addMultipleAssets = (list, files, type, library) => {
    const newFiles = files.map((f) => addAsset(list, f, type, library, true));
    onChangeMultiple(newFiles);
  };

  const addAssetData = (list, id, assetData, type, onlyReturn) => {
    const newData = {
      ...(data || {}),
      [list]: [
        ...(data?.[list] || []),
        {
          type,
          library: '',
          value: id,
          label: '',
          data: assetData,
        },
      ],
    };
    if (onlyReturn) {
      return newData;
    }
    onChange(newData);
    setNewLink({ list, id });
  };

  const addAsset = (list, file, type, library, onlyReturn) => {
    const newData = {
      ...(data || {}),
      [list]: [
        ...(data?.[list] || []),
        {
          type,
          library,
          value: file._id,
          label: file.name,
        },
      ],
    };
    if (onlyReturn) {
      return newData;
    }
    onChange(newData);
    setNewLink({ list, id: file._id });
  };

  const document = Libraries[data?.textFile?.library]?.data?.files?.find(
    (file) => file._id === data?.textFile?.value,
  );

  const embedOptions = (
    <>
      <PixiForm
        type="dropdown"
        title="Add Youtube video"
        submitLabel="Add Youtube"
        submitProps={(data) => ({
          disabled: !data.iframeUrl,
        })}
        form={{
          iframeUrl: {
            key: 'iframeUrl',
            label: 'Embed code',
            render: ({ value, setValue }) => (
              <TextInput
                label="Youtube Embed Code"
                value={value}
                onChange={(event) => {
                  const { value } = event.target;
                  setValue(value);
                }}
              />
            ),
          },
        }}
        onSubmit={({ iframeUrl }) => {
          addAssetData(
            'assets',
            randomString(10),
            {
              type: 'youtube',
              url: iframeUrl,
            },
            'iframe',
          );
          setIframeUrl('');
        }}
        target={
          <PixiDropdown.Item
            leftSection={
              <Icon
                element={
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 461.001 461.001"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        style={{ fill: '#F61C0D' }}
                        d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
  c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
  C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
  c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                      />
                    </g>
                  </svg>
                }
              />
            }
          >
            Youtube
          </PixiDropdown.Item>
        }
      />
      <PixiForm
        type="dropdown"
        title="Add Figma embed"
        submitLabel="Add figma"
        submitProps={(data) => ({
          disabled: !data.iframeUrl,
        })}
        form={{
          iframeUrl: {
            key: 'iframeUrl',
            label: 'Embed code',
            render: ({ value, setValue }) => (
              <TextInput
                label="Figma Embed Code"
                value={value}
                onChange={(event) => {
                  const { value } = event.target;
                  setValue(value);
                }}
              />
            ),
          },
        }}
        onSubmit={({ iframeUrl }) => {
          addAssetData(
            'assets',
            randomString(10),
            {
              type: 'figma',
              url: extractUrlFromIframe(iframeUrl),
            },
            'iframe',
          );
          setIframeUrl('');
        }}
        target={
          <PixiDropdown.Item
            leftSection={
              <Icon
                element={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1667"
                    height="2500"
                    viewBox="0 0 200 300"
                  >
                    <path
                      fill="#0acf83"
                      d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z"
                    />
                    <path
                      fill="#a259ff"
                      d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z"
                    />
                    <path
                      fill="#f24e1e"
                      d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z"
                    />
                    <path
                      fill="#ff7262"
                      d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z"
                    />
                    <path
                      fill="#1abcfe"
                      d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z"
                    />
                  </svg>
                }
              />
            }
          >
            Figma
          </PixiDropdown.Item>
        }
      />
      <PixiForm
        type="dropdown"
        title="Add Storybook embed"
        submitLabel="Add storybook"
        submitProps={(data) => ({
          disabled: !data.iframeUrl,
        })}
        form={{
          iframeUrl: {
            key: 'iframeUrl',
            label: 'Embed code',
            render: ({ value, setValue }) => (
              <TextInput
                label="Storybook Embed Code"
                value={value}
                onChange={(event) => {
                  const { value } = event.target;
                  setValue(value);
                }}
              />
            ),
          },
        }}
        onSubmit={({ iframeUrl }) => {
          addAssetData(
            'assets',
            randomString(10),
            {
              type: 'storybook',
              url: iframeUrl,
            },
            'iframe',
          );
          setIframeUrl('');
        }}
        target={
          <PixiDropdown.Item
            leftSection={
              <Icon
                element={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="641"
                    height="799"
                    viewBox="0 0 641 799"
                  >
                    <defs>
                      <path
                        id="a"
                        d="M24.759 735.61.029 76.672c-.817-21.762 15.87-40.206 37.604-41.564L598.105.079c22.124-1.382 41.18 15.432 42.562 37.555.052.834.078 1.669.078 2.504v718.028c0 22.167-17.97 40.136-40.136 40.136-.6 0-1.201-.013-1.801-.04L63.066 774.2c-20.865-.937-37.524-17.72-38.307-38.59Z"
                      />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <path
                        fill="#FF4785"
                        fillRule="nonzero"
                        d="M24.759 735.61.029 76.672c-.817-21.762 15.87-40.206 37.604-41.564L598.105.079c22.124-1.382 41.18 15.432 42.562 37.555.052.834.078 1.669.078 2.504v718.028c0 22.167-17.97 40.136-40.136 40.136-.6 0-1.201-.013-1.801-.04L63.066 774.2c-20.865-.937-37.524-17.72-38.307-38.59Z"
                      />
                      <g>
                        <mask id="b" fill="#fff">
                          <use xlinkHref="#a" />
                        </mask>
                        <path
                          fill="#FFF"
                          fillRule="nonzero"
                          d="m473.142 98.124 3.828-92.076L553.94 0l3.316 94.955a5.987 5.987 0 0 1-9.689 4.912l-29.682-23.382-35.142 26.658a5.987 5.987 0 0 1-9.601-5.019Zm-98.437 202.767c0 15.616 105.184 8.131 119.304-2.837 0-106.338-57.058-162.217-161.542-162.217-104.483 0-163.023 56.748-163.023 141.87 0 148.255 200.074 151.093 200.074 231.959 0 22.699-11.115 36.177-35.569 36.177-31.863 0-44.46-16.273-42.979-71.603 0-12.003-121.526-15.745-125.231 0-9.435 134.083 74.101 172.758 169.692 172.758 92.627 0 165.247-49.373 165.247-138.751 0-158.895-203.038-154.639-203.038-233.377 0-31.92 23.712-36.177 37.791-36.177 14.82 0 41.497 2.612 39.274 62.198Z"
                          mask="url(#b)"
                        />
                      </g>
                    </g>
                  </svg>
                }
              />
            }
          >
            Storybook
          </PixiDropdown.Item>
        }
      />
      <PixiForm
        type="dropdown"
        title="Add Microsoft form embed"
        submitLabel="Add"
        submitProps={(data) => ({
          disabled: !data.embedCode,
        })}
        form={{
          embedCode: {
            key: 'embedCode',
            label: 'Embed code',
            render: ({ value, setValue }) => (
              <TextInput
                label="Form URL or embed code"
                value={value}
                onChange={(event) => {
                  const { value } = event.target;
                  const iframeMatch = value.match(/src="([^"]+)"/);
                  if (iframeMatch) {
                    return setValue(iframeMatch[1]);
                  }
                  setValue(value);
                }}
              />
            ),
          },
        }}
        onOpen={() => {
          setIframeUrl('');
        }}
        onSubmit={({ embedCode }) => {
          addAssetData(
            'assets',
            randomString(10),
            {
              type: 'microsoft_forms',
              url: embedCode,
            },
            'iframe',
          );
          setIframeUrl('');
        }}
        target={
          <PixiDropdown.Item
            leftSection={
              <Icon
                element={
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430 400">
                    <path
                      fill="#036c70"
                      d="M430 200v181.7c0 10.1-8.2 18.3-18.3 18.3H118.3c-10.1 0-18.3-8.2-18.3-18.3V200l170-20 160 20z"
                    />
                    <path
                      fill="#37c6d0"
                      d="M118.3 0h293.4C421.8 0 430 8.2 430 18.3V200H100V18.3C100 8.2 108.2 0 118.3 0z"
                    />
                    <path fill="#1a9ba1" d="M280 250h100v100H280z" />
                    <circle cx="330" cy="100" r="60" fill="#1a9ba1" />
                    <circle cx="330" cy="100" r="30" fill="#036c70" />
                    <path
                      d="M221.7 80H100v250h121.7c10.1 0 18.3-8.2 18.3-18.3V98.3c0-10.1-8.2-18.3-18.3-18.3z"
                      opacity=".1"
                    />
                    <path
                      d="M211.7 90H100v250h111.7c10.1 0 18.3-8.2 18.3-18.3V108.3c0-10.1-8.2-18.3-18.3-18.3z"
                      opacity=".2"
                    />
                    <path
                      d="M211.7 90H100v230h111.7c10.1 0 18.3-8.2 18.3-18.3V108.3c0-10.1-8.2-18.3-18.3-18.3z"
                      opacity=".2"
                    />
                    <path
                      d="M201.7 90H100v230h101.7c10.1 0 18.3-8.2 18.3-18.3V108.3c0-10.1-8.2-18.3-18.3-18.3z"
                      opacity=".2"
                    />
                    <linearGradient
                      id="a"
                      x1="38.218"
                      x2="181.782"
                      y1="326.323"
                      y2="77.677"
                      gradientTransform="matrix(1 0 0 -1 0 402)"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#058f92" />
                      <stop offset=".5" stopColor="#038489" />
                      <stop offset="1" stopColor="#026d71" />
                    </linearGradient>
                    <path
                      fill="url(#a)"
                      d="M18.3 90h183.3c10.1 0 18.3 8.2 18.3 18.3v183.3c0 10.1-8.2 18.3-18.3 18.3H18.3C8.2 310 0 301.8 0 291.7V108.3C0 98.2 8.2 90 18.3 90z"
                    />
                    <path
                      fill="#fff"
                      d="M147.1 159.8H95.5v32.5h48.1v19.3H95.5v48H72.2V140.4h75l-.1 19.4z"
                    />
                  </svg>
                }
              />
            }
          >
            Microsoft Forms
          </PixiDropdown.Item>
        }
      />
    </>
  );

  const AddAssetDropdown = useMemo(
    () =>
      assetFocus === 'color' ? (
        <Button
          inline
          primary
          background="#515151"
          className="no_global_style"
          onClick={async () => {
            const files = await selectFile({
              multiple: multipleAssets,
              libraryId: 'brandassets',
              popupProps: {
                size: 800,
              },
              selectButtonLabel: 'Select color',
              assetGridProps: {
                forceFilters: {
                  'data.type': [{ value: 'color' }],
                },
              },
            });
            if (!multipleAssets) {
              addAsset('assets', files?.[0], 'file', 'media');
            } else {
              addMultipleAssets('assets', files, 'file', 'media');
            }
          }}
        >
          <Icon name="droplet" />
          {assetLabel}
        </Button>
      ) : (
        <PixiDropdown
          width={250}
          target={
            focus.includes('asset') ? (
              <PixiButton
                inline
                primary
                bg="#515151"
                className="no_global_style"
                size="md"
                leftSection={<PixiIcon name="plus" />}
              >
                {assetLabel || `Add asset${multipleAssets ? 's' : ''}`}
              </PixiButton>
            ) : (
              <Button
                inline
                style={{ marginBottom: 10 }}
                className="no_global_style"
              >
                <Icon name="plus-lg" />
                {assetLabel || `Add asset${multipleAssets ? 's' : ''}`}
              </Button>
            )
          }
        >
          {({ setIsOpen, setIsFreezeOpen }) => (
            <>
              {(!assetFocus ||
                assetFocus === 'file' ||
                assetFocus === 'image' ||
                assetFocus === 'video' ||
                assetFocus === 'media') && (
                <>
                  {[
                    {
                      label: 'Media',
                      icon: 'image',
                      libraryId: 'media',
                      contextId: 'media',
                      selectLabel: 'Select media',
                    },
                    ...(assetFocus !== 'media'
                      ? [
                          {
                            label: 'Documents',
                            icon: 'file-lines',
                            libraryId: 'documents',
                            contextId: 'documents',
                            selectLabel: 'Select documents',
                          },
                        ]
                      : []),
                    ...(userStatus.activeServices.brand_assets
                      ? [
                          {
                            label: 'Brand assets',
                            icon: 'palette',
                            libraryId: 'brandassets',
                            contextId: 'media',
                            selectLabel: 'Select brand assets',
                          },
                        ]
                      : []),
                  ].map((lib) => (
                    <PixiDropdown.Item
                      key={lib.libraryId}
                      onClick={async () => {
                        setIsOpen(false);
                        const files = await selectFile({
                          multiple: multipleAssets,
                          libraryId: lib.libraryId,
                          popupProps: {
                            title: lib.selectLabel,
                          },
                          selectButtonLabel: lib.selectLabel,
                          assetGridProps: {
                            // forceFilters: {
                            //   'data.type': [{ value: 'color' }],
                            // },
                          },
                        });
                        if (!files?.length) {
                          return;
                        }
                        if (!multipleAssets) {
                          addAsset('assets', files?.[0], 'file', lib.contextId);
                        } else {
                          addMultipleAssets(
                            'assets',
                            files,
                            'file',
                            lib.contextId,
                          );
                        }
                      }}
                      icon={lib.icon}
                    >
                      {lib.label}
                    </PixiDropdown.Item>
                  ))}
                </>
              )}
              {(!assetFocus || assetFocus === 'iframe') && (
                <>
                  {assetFocus === 'iframe' ? (
                    <>{embedOptions}</>
                  ) : (
                    <PixiDropdown
                      width={240}
                      trigger="hover"
                      position="right-start"
                      target={
                        <PixiDropdown.Item withChevron icon="code">
                          Embed
                        </PixiDropdown.Item>
                      }
                    >
                      {embedOptions}
                    </PixiDropdown>
                  )}
                </>
              )}
              {(!assetFocus || assetFocus === 'collection') && (
                <>
                  {[
                    { label: 'Media collection', context: Libraries.media },
                    {
                      label: 'Document collection',
                      context: Libraries.documents,
                    },
                  ].map((library) => (
                    <PixiDropdown
                      width={240}
                      height={300}
                      trigger="hover"
                      key={library.label}
                      position="right-start"
                      target={
                        <PixiDropdown.Item withChevron icon="rectangle-history">
                          {library.label}
                        </PixiDropdown.Item>
                      }
                    >
                      {library.context.stores.data.collections.map(
                        (collection) => (
                          <PixiDropdown.Item
                            key={collection._id}
                            onClick={async () => {
                              if (!multipleAssets) {
                                addAsset(
                                  'assets',
                                  collection,
                                  'collection',
                                  library.context?.type,
                                );
                              } else {
                                addMultipleAssets(
                                  'assets',
                                  [collection],
                                  'collection',
                                  library.context?.type,
                                );
                              }
                            }}
                            leftSection={
                              <ThemeIcon
                                variant="light"
                                color="dark"
                                style={{ overflow: 'hidden' }}
                              >
                                <Image
                                  w="100%"
                                  h="100%"
                                  fit="cover"
                                  src={library.context.getCollectionThumbnailUrl(
                                    collection,
                                  )}
                                />
                              </ThemeIcon>
                            }
                          >
                            {collection.name}
                          </PixiDropdown.Item>
                        ),
                      )}
                    </PixiDropdown>
                  ))}
                </>
              )}
              {(!assetFocus || assetFocus === 'board') && (
                <>
                  <AddBoard
                    value={allBlockAssets
                      ?.filter((link) => link.type === 'board')
                      .map((link) => link.value)}
                    multipleAssets={multipleAssets}
                    onBoardClick={(board) => {
                      if (!multipleAssets) {
                        addAsset('assets', board, 'board', '');
                      } else {
                        addMultipleAssets('assets', board, 'board', '');
                      }
                      setIsFreezeOpen(false);
                      setIsOpen(false);
                    }}
                    onClose={() => {
                      setIsFreezeOpen(false);
                      setIsOpen(false);
                    }}
                    type="standard"
                    isPopup
                    library="media"
                    trigger={(isOpen, setIsOpen) => (
                      <PixiDropdown.Item
                        onClick={() => {
                          setIsFreezeOpen(true);
                          setIsOpen(!isOpen);
                        }}
                        icon="layer-group"
                      >
                        Board
                      </PixiDropdown.Item>
                    )}
                  />
                  {userStatus.activeServices.brand_assets && (
                    <AddBoard
                      value={allBlockAssets
                        ?.filter((link) => link.type === 'board')
                        .map((link) => link.value)}
                      multipleAssets={multipleAssets}
                      onBoardClick={(board) => {
                        if (!multipleAssets) {
                          addAsset('assets', board, 'board', '');
                        } else {
                          addMultipleAssets('assets', board, 'board', '');
                        }
                        setIsFreezeOpen(false);
                        setIsOpen(false);
                      }}
                      type="brand"
                      name="sub-guide"
                      isPopup
                      library="media"
                      trigger={(isOpen, setIsOpen) => (
                        <PixiDropdown.Item
                          onClick={() => {
                            setIsFreezeOpen(true);
                            setIsOpen(!isOpen);
                          }}
                          icon="palette"
                        >
                          Sub-guide
                        </PixiDropdown.Item>
                      )}
                    />
                  )}
                </>
              )}
            </>
          )}
        </PixiDropdown>
      ),
    [data, iframeUrl],
  );

  const className = ['ContentSection__block'];

  if (data?.withPadding) {
    className.push('ContentSection__block--withPadding');
  }

  const openCollectionPreviewPrefs = {
    readOnly: true,
    assetGridProps: {
      fileToolbar: boardPreferences?.enableDownloadBag
        ? ['download', 'add_to_bag']
        : ['download'],
    },
    // openAssetPreviewPrefs: (file) => {
    //   return {
    //     extraActions: (
    //       <PixiButton
    //         size="md"
    //         variant="glass"
    //         onClick={() => Module.onFileShare(file)}
    //         leftSection={<PixiIcon name="link" />}
    //       >
    //         Copy link to file
    //       </PixiButton>
    //     ),
    //   };
    // },
  };

  return (
    <>
      {isRearrange && (
        <Button
          onMouseDown={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setIsRearrange(false);
          }}
          inline
          className="no_global_style"
          primary
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: 10,
          }}
        >
          <Icon name="CheckCircle" />
          Done rearranging
        </Button>
      )}
      <div className={className.join(' ')} ref={blockRef}>
        {!isOnlyOne && Module.isEditing && !isRearrange && (
          <PinkToolbar parentRef={blockRef} position="top">
            <Icon
              name={isRearrange ? 'CheckCircle' : 'ArrowsMove'}
              bubble
              button
              onMouseDown={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setIsRearrange(!isRearrange);
              }}
            />
            <Icon
              name={
                !data?.withPadding
                  ? 'arrows-angle-contract'
                  : 'arrows-angle-expand'
              }
              bubble
              button
              onClick={() => {
                onChange({
                  ...(data || {}),
                  withPadding: !data?.withPadding,
                });
              }}
            />
            <Tooltip
              message={
                data?.isHidden
                  ? 'Show in section'
                  : 'Hide from section (Will still be visible in menu)'
              }
            >
              <Icon
                bubble
                button
                name={data?.isHidden ? 'eye' : 'eye-fill'}
                onClick={() => {
                  onChange({
                    ...(data || {}),
                    isHidden: !data?.isHidden,
                  });
                }}
              />
            </Tooltip>
            {!disableDelete && (
              <Icon name="TrashFill" bubble button onClick={onRemove} />
            )}
          </PinkToolbar>
        )}
        {!data?.assets?.length &&
          !disable.includes('asset') &&
          Module.isEditing && (
            <>
              {focus.includes('asset') ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    width: '100%',
                    minHeight: 230,
                    height: disable.includes('text') ? '100%' : 230,
                    background: 'rgba(0, 0, 0, 0.05)',
                    marginBottom:
                      !disable.includes('text') &&
                      !Module.metadata?.contentSection?.contentNoSpace
                        ? 10
                        : 0,
                  }}
                >
                  {AddAssetDropdown}
                </Flex>
              ) : (
                Module.isEditing && AddAssetDropdown
              )}
            </>
          )}
        {!!data?.assets?.length && (
          <Flex
            wrap
            alignItems="center"
            justifyContent="center"
            style={{
              marginBottom:
                !disable.includes('text') &&
                (!Module.metadata?.contentSection?.contentNoSpace ||
                  Module.isEditing)
                  ? 10
                  : 0,
            }}
          >
            {data?.assets?.map((asset) => {
              if (asset.type === 'file') {
                const library =
                  asset.library === 'brandassets' ? 'media' : asset.library;
                const file = Libraries[library].data.files.find(
                  (file) => file._id === asset.value,
                );
                if (!file) {
                  return <></>;
                }
                return (
                  <>
                    {file.data?.type === 'color' ||
                    (Libraries[library].fileIsPreviewable(file) &&
                      file.file?.ext !== 'mp3' &&
                      file?.type === 'media' &&
                      assetFocus !== 'file' &&
                      !asset.preferences?.assetAsFile) ? (
                      <div
                        ref={assetThumb}
                        className={`ContentSection__AssetThumbnail ${
                          asset.preferences?.withPadding
                            ? 'ContentSection__AssetThumbnail--withPadding'
                            : ''
                        } ${
                          asset.preferences?.assetDisplayAs ||
                          metadata.assetDisplayAs
                            ? `ContentSection__AssetThumbnail_as__${
                                asset.preferences?.assetDisplayAs ||
                                metadata.assetDisplayAs
                              }`
                            : ''
                        } ${
                          asset.preferences?.assetImageScale
                            ? `ContentSection__AssetThumbnail_scale__${asset?.preferences?.assetImageScale}`
                            : ''
                        }`}
                        style={{
                          display: 'flex',
                          position: 'relative',
                        }}
                      >
                        <div
                          className="ContentSection__AssetThumbnail__image"
                          style={{
                            ...(asset.preferences?.assetLink?.type
                              ? {
                                  cursor: 'pointer',
                                }
                              : {}),
                          }}
                          onClick={() => {
                            if (asset?.preferences?.assetLink?.type) {
                              const link = asset?.preferences?.assetLink;
                              const { data } = populateLinks(link);
                              if (link.type === 'collection') {
                                openCollectionPreview(
                                  data,
                                  openCollectionPreviewPrefs,
                                );
                                analytics.trackEvent(
                                  'Board button link clicked',
                                  {
                                    'Board Id': Board.data?._id,
                                    'Board button type': link.type,
                                    'Board button action': `${data?._id}`,
                                  },
                                );
                              }
                              if (link.type === 'board') {
                                Board.openBoard?.(data);
                                analytics.trackEvent(
                                  'Board button link clicked',
                                  {
                                    'Board Id': Board.data?._id,
                                    'Board button type': link.type,
                                    'Board button action': `${data?._id}`,
                                  },
                                );
                              }
                              if (link?.type === 'file') {
                                // fixme: for Jacob. We do not have Libraries.media.downloadFilesAsZip.
                                // fixme: Use downloadBag.downloadFiles(files, community slug, board context) instead.
                                // if (link.data.data?.type === 'font-family') {
                                //   Libraries.media.downloadFilesAsZip(link.data.data.files);
                                // }
                                downloadUrl(generateUrl(data?.file?.url));
                                analytics.trackEvent(
                                  'Board button link clicked',
                                  {
                                    'Board Id': Board.data?._id,
                                    'Board button type': link.type,
                                    'Board button action': `${data?._id}`,
                                  },
                                );
                              }
                              if (link.type === 'email') {
                                openInNewTab(`mailto:${link.value}`);
                                analytics.trackEvent(
                                  'Board button link clicked',
                                  {
                                    'Board Id': Board.data?._id,
                                    'Board button type': link.type,
                                    'Board button action': `mailto:${link.value}`,
                                  },
                                );
                              }
                              if (link.type === 'custom') {
                                const url =
                                  link.value.indexOf('://') === -1
                                    ? `http://${link.value}`
                                    : link.value;
                                analytics.trackEvent(
                                  'Board button link clicked',
                                  {
                                    'Board Id': Board.data?._id,
                                    'Board button type': link.type,
                                    'Board button action': url,
                                  },
                                );
                                if (link.preferences?.openInNewWindow) {
                                  openInNewTab(url);
                                } else {
                                  openLink(url, true);
                                }
                              }
                            }
                          }}
                        >
                          {!Module.isEditing &&
                            asset.preferences?.assetDownloadable && (
                              <PinkToolbar
                                parentRef={assetThumb}
                                {...{
                                  size: 'small',
                                  overlay: true,
                                  floating: true,
                                }}
                              >
                                <AssetToolbar
                                  file={file}
                                  onPreview={() => {
                                    openAssetPreview(file, {});
                                  }}
                                  actionIconProps={{
                                    variant: 'transparent',
                                    c: 'white',
                                  }}
                                  iconProps={{
                                    c: 'white',
                                  }}
                                  includeDownloadBag={
                                    boardPreferences?.enableDownloadBag
                                  }
                                  specificActions={['download', 'add_to_bag']}
                                  downloadWrapper={
                                    boardPreferences?.terms?.download?.active
                                      ? (trigger, onConfirm, label) => (
                                          <TermsConfirm
                                            trigger={trigger}
                                            onConfirm={onConfirm}
                                            label={label}
                                            content={
                                              boardPreferences?.terms?.download
                                                ?.active &&
                                              boardPreferences?.terms?.download
                                                ?.message
                                            }
                                          />
                                        )
                                      : undefined
                                  }
                                />
                              </PinkToolbar>
                            )}
                          {file.data?.type === 'color' ? (
                            <ColorItem
                              showVariant={
                                asset?.preferences?.disableColorVariant
                              }
                              data={file}
                              Module={Module}
                              metadata={metadata}
                            />
                          ) : (
                            <FileThumbnailV2
                              file={
                                forceReplaceAsset
                                  ? forceReplaceAsset?.file
                                  : file?.file
                              }
                              // prefs={{ onlyImage: true }}
                              ignoreFileSize
                              preferSize="large"
                              context={Libraries[library]}
                              videoProps={
                                asset.preferences?.assetVideoAutoPlay
                                  ? {
                                      autoPlay: true,
                                      playsInline: true,
                                      muted: true,
                                      loop: true,
                                      controls: false,
                                    }
                                  : {
                                      controlsList: 'nodownload',
                                    }
                              }
                              videoControls
                              imageStyle={{
                                maxWidth: '100%',
                                ...(file?.file?.ext === 'svg'
                                  ? { objectFit: 'fill' }
                                  : {}),
                              }}
                            />
                          )}
                        </div>
                        {Module.isEditing &&
                          !!asset?.preferences?.assetLink?.type && (
                            <PinkToolbar
                              size="small"
                              overlay
                              parentRef={assetThumb}
                              floating
                              style={{
                                left: 5,
                                right: 'auto',
                              }}
                            >
                              <Dropdown
                                trigger={
                                  <div>
                                    <Tooltip
                                      message={`Linking to ${asset?.preferences?.assetLink?.type}`}
                                      noTriggerWrapper
                                    >
                                      <Icon name="link-45deg" bubble button />
                                    </Tooltip>
                                  </div>
                                }
                              >
                                <Dropdown.Option
                                  onClick={() => {
                                    onChange({
                                      ...(data || {}),
                                      assets: data?.assets.map((l) =>
                                        l.value === asset.value
                                          ? {
                                              ...l,
                                              preferences: {
                                                ...(l.preferences || {}),
                                                assetLink: undefined,
                                              },
                                            }
                                          : l,
                                      ),
                                    });
                                  }}
                                >
                                  Remove link
                                </Dropdown.Option>
                              </Dropdown>
                            </PinkToolbar>
                          )}
                        {Module.isEditing && (
                          <PinkToolbar
                            parentRef={assetThumb}
                            style={{ zIndex: 99 }}
                            {...{
                              size: 'small',
                              overlay: true,
                              floating: true,
                            }}
                          >
                            {file.data?.type === 'color' && (
                              <Tooltip
                                message={
                                  !asset.preferences?.disableDownload
                                    ? 'Disable Color Variants'
                                    : 'Enable Color Variants'
                                }
                              >
                                <Icon
                                  name={
                                    asset.preferences?.disableColorVariant
                                      ? 'palette-fill'
                                      : 'palette'
                                  }
                                  bubble
                                  button
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    onChange({
                                      ...(data || {}),
                                      assets: data?.assets.map((l) =>
                                        l.value === asset.value
                                          ? {
                                              ...l,
                                              preferences: {
                                                ...(l.preferences || {}),
                                                disableColorVariant:
                                                  !asset.preferences
                                                    ?.disableColorVariant,
                                              },
                                            }
                                          : l,
                                      ),
                                    });
                                  }}
                                />
                              </Tooltip>
                            )}
                            <>
                              {file?.data?.type !== 'color' && (
                                <>
                                  <PixiDropdown
                                    classNameDropdown="link_menu"
                                    target={
                                      <ActionIcon
                                        p="sm"
                                        size="lg"
                                        variant="subtle"
                                        c="white"
                                      >
                                        <Icon name="link-45deg" bubble button />
                                      </ActionIcon>
                                    }
                                  >
                                    {!!asset?.preferences?.assetLink?.type && (
                                      <PixiDropdown.Item
                                        onClick={() => {
                                          onChange({
                                            ...(data || {}),
                                            assets: data?.assets.map((l) =>
                                              l.value === asset.value
                                                ? {
                                                    ...l,
                                                    preferences: {
                                                      ...(l.preferences || {}),
                                                      assetLink: undefined,
                                                    },
                                                  }
                                                : l,
                                            ),
                                          });
                                        }}
                                        icon="trash-can"
                                      >
                                        Remove link
                                      </PixiDropdown.Item>
                                    )}
                                    {!!asset?.preferences?.assetLink?.type && (
                                      <Dropdown.Label>
                                        Replace link
                                      </Dropdown.Label>
                                    )}
                                    <LinkForm
                                      position="right-start"
                                      onSubmit={({
                                        url,
                                        label,
                                        preferences,
                                      }) => {
                                        onChange({
                                          ...(data || {}),
                                          assets: data?.assets.map((l) =>
                                            l.value === asset.value
                                              ? {
                                                  ...l,
                                                  preferences: {
                                                    ...(l.preferences || {}),
                                                    assetLink: {
                                                      type: 'custom',
                                                      value: url,
                                                      label,
                                                      preferences,
                                                    },
                                                  },
                                                }
                                              : l,
                                          ),
                                        });
                                      }}
                                      target={
                                        <PixiDropdown.Item icon="link">
                                          Custom link
                                        </PixiDropdown.Item>
                                      }
                                    />
                                    <LinkForm
                                      variant="email"
                                      position="right-start"
                                      onSubmit={({ url, label, preferences }) =>
                                        onChange({
                                          ...(data || {}),
                                          assets: data?.assets.map((l) =>
                                            l.value === asset.value
                                              ? {
                                                  ...l,
                                                  preferences: {
                                                    ...(l.preferences || {}),
                                                    assetLink: {
                                                      type: 'email',
                                                      value: url,
                                                      label,
                                                      preferences,
                                                    },
                                                  },
                                                }
                                              : l,
                                          ),
                                        })
                                      }
                                      target={
                                        <PixiDropdown.Item icon="envelope">
                                          Email
                                        </PixiDropdown.Item>
                                      }
                                    />
                                    <LinkForm
                                      variant="board"
                                      position="right-start"
                                      onSubmit={({
                                        board,
                                        label,
                                        preferences,
                                      }) =>
                                        onChange({
                                          ...(data || {}),
                                          assets: data?.assets.map((l) =>
                                            l.value === asset.value
                                              ? {
                                                  ...l,
                                                  preferences: {
                                                    ...(l.preferences || {}),
                                                    assetLink: {
                                                      type: 'board',
                                                      value: board._id,
                                                      preferences,
                                                    },
                                                  },
                                                }
                                              : l,
                                          ),
                                        })
                                      }
                                      target={
                                        <PixiDropdown.Item icon="envelope">
                                          Board
                                        </PixiDropdown.Item>
                                      }
                                    />
                                    <LinkForm
                                      variant="media_collection"
                                      position="right-start"
                                      onSubmit={({
                                        collection,
                                        label,
                                        preferences,
                                      }) =>
                                        onChange({
                                          ...(data || {}),
                                          assets: data?.assets.map((l) =>
                                            l.value === asset.value
                                              ? {
                                                  ...l,
                                                  preferences: {
                                                    ...(l.preferences || {}),
                                                    assetLink: {
                                                      type: 'collection',
                                                      library: 'media',
                                                      value: collection._id,
                                                      preferences,
                                                    },
                                                  },
                                                }
                                              : l,
                                          ),
                                        })
                                      }
                                      target={
                                        <PixiDropdown.Item icon="rectangle-history">
                                          Media collection
                                        </PixiDropdown.Item>
                                      }
                                    />
                                    <LinkForm
                                      variant="document_collection"
                                      position="right-start"
                                      onSubmit={({
                                        collection,
                                        label,
                                        preferences,
                                      }) =>
                                        onChange({
                                          ...(data || {}),
                                          assets: data?.assets.map((l) =>
                                            l.value === asset.value
                                              ? {
                                                  ...l,
                                                  preferences: {
                                                    ...(l.preferences || {}),
                                                    assetLink: {
                                                      type: 'collection',
                                                      library: 'documents',
                                                      value: collection._id,
                                                      preferences,
                                                    },
                                                  },
                                                }
                                              : l,
                                          ),
                                        })
                                      }
                                      target={
                                        <PixiDropdown.Item icon="rectangle-history">
                                          Documents collection
                                        </PixiDropdown.Item>
                                      }
                                    />
                                  </PixiDropdown>
                                  <Tooltip
                                    message={`${
                                      asset.preferences?.assetDownloadable
                                        ? 'Disable'
                                        : 'Enable'
                                    } media preview and download`}
                                  >
                                    <Icon
                                      name={`cloud-check${
                                        asset.preferences?.assetDownloadable
                                          ? '-fill'
                                          : ''
                                      }`}
                                      bubble
                                      button
                                      disabled={
                                        !!asset?.preferences?.assetLink?.type
                                      }
                                      onClick={() => {
                                        onChange({
                                          ...(data || {}),
                                          assets: data?.assets.map((l) =>
                                            l.value === asset.value
                                              ? {
                                                  ...l,
                                                  preferences: {
                                                    ...(l.preferences || {}),
                                                    assetDownloadable:
                                                      !asset.preferences
                                                        ?.assetDownloadable,
                                                  },
                                                }
                                              : l,
                                          ),
                                        });
                                      }}
                                    />
                                  </Tooltip>
                                  {Libraries[library].fileIsPreviewable(
                                    file,
                                  ) && (
                                    <Tooltip message="Show as file">
                                      <Icon
                                        name="file-earmark-image"
                                        bubble
                                        button
                                        disabled={
                                          !!asset?.preferences?.assetLink?.type
                                        }
                                        onClick={() => {
                                          onChange({
                                            ...(data || {}),
                                            assets: data?.assets.map((l) =>
                                              l.value === asset.value
                                                ? {
                                                    ...l,
                                                    preferences: {
                                                      ...(l.preferences || {}),
                                                      assetAsFile:
                                                        !asset.preferences
                                                          ?.assetAsFile,
                                                    },
                                                  }
                                                : l,
                                            ),
                                          });
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                  {asset.preferences?.withPadding && (
                                    <Icon
                                      name={
                                        !asset.preferences?.withPadding
                                          ? 'arrows-angle-contract'
                                          : 'arrows-angle-expand'
                                      }
                                      bubble
                                      button
                                      disabled={
                                        !!asset?.preferences?.assetLink?.type
                                      }
                                      onClick={() => {
                                        onChange({
                                          ...(data || {}),
                                          assets: data?.assets.map((l) =>
                                            l.value === asset.value
                                              ? {
                                                  ...l,
                                                  preferences: {
                                                    ...(l.preferences || {}),
                                                    withPadding:
                                                      !asset.preferences
                                                        ?.withPadding,
                                                  },
                                                }
                                              : l,
                                          ),
                                        });
                                      }}
                                    />
                                  )}
                                  {!!asset.preferences?.assetDisplayAs && (
                                    <Dropdown
                                      useV2
                                      disabled={
                                        !!asset?.preferences?.assetLink?.type
                                      }
                                      onChange={(value) => {
                                        onChange({
                                          ...(data || {}),
                                          assets: data?.assets.map((l) =>
                                            l.value === asset.value
                                              ? {
                                                  ...l,
                                                  preferences: {
                                                    ...(l.preferences || {}),
                                                    assetImageScale: value,
                                                  },
                                                }
                                              : l,
                                          ),
                                        });
                                      }}
                                      trigger={
                                        <Icon
                                          name="aspect-ratio"
                                          bubble
                                          button
                                        />
                                      }
                                    >
                                      <Dropdown.Label>
                                        Display as
                                      </Dropdown.Label>
                                      <Dropdown.Option
                                        value={null}
                                        selected={
                                          !asset.preferences?.assetImageScale
                                        }
                                      >
                                        <Icon name="arrow-down-up" />
                                        Auto
                                      </Dropdown.Option>
                                      <Dropdown.Option
                                        value="cover"
                                        selected={
                                          asset.preferences?.assetImageScale ===
                                          'cover'
                                        }
                                      >
                                        <Icon name="arrows-angle-expand" />
                                        Cover
                                      </Dropdown.Option>
                                      <Dropdown.Option
                                        value="contain"
                                        selected={
                                          asset.preferences?.assetImageScale ===
                                          'contain'
                                        }
                                      >
                                        <Icon name="bounding-box" />
                                        Contain
                                      </Dropdown.Option>
                                    </Dropdown>
                                  )}
                                  <Dropdown
                                    useV2
                                    onChange={(value) => {
                                      onChange({
                                        ...(data || {}),
                                        assets: data?.assets.map((l) =>
                                          l.value === asset.value
                                            ? {
                                                ...l,
                                                preferences: {
                                                  ...(l.preferences || {}),
                                                  assetDisplayAs: value,
                                                },
                                              }
                                            : l,
                                        ),
                                      });
                                    }}
                                    trigger={
                                      <Icon name="image" bubble button />
                                    }
                                  >
                                    <Dropdown.Label>Display as</Dropdown.Label>
                                    <Dropdown.Option
                                      value={null}
                                      selected={
                                        !asset.preferences?.assetDisplayAs
                                      }
                                    >
                                      <Icon name="arrow-down-up" />
                                      Auto
                                    </Dropdown.Option>
                                    <Dropdown.Option
                                      value="circle"
                                      selected={
                                        asset.preferences?.assetDisplayAs ===
                                        'circle'
                                      }
                                    >
                                      <Icon name="circle" />
                                      Circle
                                    </Dropdown.Option>
                                    <Dropdown.Option
                                      value="rectangle"
                                      selected={
                                        asset.preferences?.assetDisplayAs ===
                                        'rectangle'
                                      }
                                    >
                                      <Icon name="textarea-resize" />
                                      16:9
                                    </Dropdown.Option>
                                    <Dropdown.Option
                                      value="square"
                                      selected={
                                        asset.preferences?.assetDisplayAs ===
                                        'square'
                                      }
                                    >
                                      <Icon name="square" />
                                      Square
                                    </Dropdown.Option>
                                  </Dropdown>
                                </>
                              )}
                            </>
                            {file?.file?.ext === 'mp4' && (
                              <Tooltip message="Automatically play video and loop. Note: Video will be muted">
                                <Icon
                                  name={
                                    asset.preferences?.assetVideoAutoPlay
                                      ? 'play-circle-fill'
                                      : 'play-circle'
                                  }
                                  bubble
                                  button
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    onChange({
                                      ...(data || {}),
                                      assets: data?.assets.map((l) =>
                                        l.value === asset.value
                                          ? {
                                              ...l,
                                              preferences: {
                                                ...(l.preferences || {}),
                                                assetVideoAutoPlay:
                                                  !asset.preferences
                                                    ?.assetVideoAutoPlay,
                                              },
                                            }
                                          : l,
                                      ),
                                    });
                                  }}
                                />
                              </Tooltip>
                            )}
                            <Icon
                              name="x-lg"
                              bubble
                              button
                              onClick={() => {
                                onChange({
                                  ...(data || {}),
                                  assets: data?.assets.filter(
                                    (l) => l.value !== asset.value,
                                  ),
                                });
                              }}
                            />
                          </PinkToolbar>
                        )}
                      </div>
                    ) : (
                      <>
                        <File
                          key={asset.value}
                          toolbar={
                            Module.isEditing ? (
                              <>
                                {asset.preferences?.assetAsFile && (
                                  <Tooltip message={`${'Show as thumbnail'}`}>
                                    <Icon
                                      name="file-earmark-image-fill"
                                      bubble
                                      button
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        onChange({
                                          ...(data || {}),
                                          assets: data?.assets.map((l) =>
                                            l.value === asset.value
                                              ? {
                                                  ...l,
                                                  preferences: {
                                                    ...(l.preferences || {}),
                                                    assetAsFile:
                                                      !asset.preferences
                                                        ?.assetAsFile,
                                                  },
                                                }
                                              : l,
                                          ),
                                        });
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                <Tooltip
                                  message={
                                    !asset.preferences?.disableDownload
                                      ? 'Disable download'
                                      : 'Enable download'
                                  }
                                >
                                  <Icon
                                    name={
                                      asset.preferences?.disableDownload
                                        ? 'cloud-slash-fill'
                                        : 'cloud-slash'
                                    }
                                    bubble
                                    button
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      onChange({
                                        ...(data || {}),
                                        assets: data?.assets.map((l) =>
                                          l.value === asset.value
                                            ? {
                                                ...l,
                                                preferences: {
                                                  ...(l.preferences || {}),
                                                  disableDownload:
                                                    !asset.preferences
                                                      ?.disableDownload,
                                                },
                                              }
                                            : l,
                                        ),
                                      });
                                    }}
                                  />
                                </Tooltip>
                                <Icon
                                  name="x-lg"
                                  bubble
                                  button
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    onChange({
                                      ...(data || {}),
                                      assets: data?.assets.filter(
                                        (l) => l.value !== asset.value,
                                      ),
                                    });
                                  }}
                                />
                              </>
                            ) : !asset.preferences?.disableDownload ? (
                              <AssetToolbar
                                disableActions={['favorite']}
                                includeDownloadBag={
                                  boardPreferences?.enableDownloadBag
                                }
                                actionIconProps={{
                                  variant: 'transparent',
                                  c: 'white',
                                }}
                                iconProps={{
                                  c: 'white',
                                }}
                                specificActions={['download', 'add_to_bag']}
                                downloadWrapper={
                                  boardPreferences?.terms?.download?.active
                                    ? (trigger, onConfirm, label) => (
                                        <TermsConfirm
                                          trigger={trigger}
                                          onConfirm={onConfirm}
                                          label={label}
                                          content={
                                            boardPreferences?.terms?.download
                                              ?.active &&
                                            boardPreferences?.terms?.download
                                              ?.message
                                          }
                                        />
                                      )
                                    : undefined
                                }
                                file={file}
                              />
                            ) : (
                              <></>
                            )
                          }
                          onClick={() => {
                            openAssetPreview(file, {
                              disableDownload:
                                asset.preferences?.disableDownload,
                              extraActions: (
                                <PixiButton
                                  size="md"
                                  variant="glass"
                                  onClick={() => Module.onFileShare(file)}
                                  leftSection={<PixiIcon name="link" />}
                                >
                                  Copy link to file
                                </PixiButton>
                              ),
                            });
                            setSelectedFilePreferences({
                              disableDownload:
                                asset.preferences?.disableDownload,
                            });
                          }}
                          toolbarProps={{
                            overlay: true,
                            floating: true,
                            branded: true,
                          }}
                          file={{ data: file }}
                          fileType={file?.type || 'unknown'}
                          context={Libraries[library]}
                        />
                      </>
                    )}
                  </>
                );
              }

              if (asset.type === 'board') {
                const board = Boards.data.find((b) => b._id === asset.value);
                if (!board?._id) {
                  return <></>;
                }
                return (
                  <BoardCard
                    key={board._id}
                    board={board}
                    style={{ width: '100%', padding: 0 }}
                    onClick={() => {
                      if (!Module.isEditing) {
                        Board.openBoard(board);
                      }
                    }}
                    contextMenuProps={{
                      overlay: true,
                      floating: true,
                      size: 'small',
                    }}
                    thumbnailType={
                      Module.metadata?.contentSection?.boardDisplayAs ||
                      (Board.type !== 'standard' ? 'textOutside' : 'standard')
                    }
                    contextMenu={
                      Module.isEditing && [
                        {
                          icon: 'x-lg',
                          tooltip: 'Remove board',
                          onClick: () => {
                            onChange({
                              ...(data || {}),
                              assets: data?.assets.filter(
                                (l) => l.value !== asset.value,
                              ),
                            });
                          },
                        },
                        {
                          icon: 'pencil-fill',
                          tooltip: 'Edit board',
                          onClick: () => {
                            const url = Board.openBoard(board, '', '', true);
                            openInNewTab(`${window.location.origin}#${url}`);
                          },
                        },
                      ]
                    }
                  />
                );
              }
              if (asset.type === 'collection') {
                const collection = Libraries[
                  asset.library
                ].data.collections.find((col) => col._id === asset.value);
                if (!collection?._id) {
                  return <></>;
                }
                return (
                  <DocumentCollection
                    key={collection?._id}
                    onClick={() => {
                      analytics.trackEvent('Collection clicked', {
                        'Collection Name': collection.name,
                        'Collection Id': collection._id,
                      });
                      openCollectionPreview(collection, {
                        ...openCollectionPreviewPrefs,
                      });
                    }}
                    context={Libraries[asset.library]}
                    collection={collection}
                    contextMenuProps={{ overlay: true }}
                    contextMenu={
                      Module.isEditing && (
                        <Icon
                          key="xx"
                          name="x-lg"
                          bubble
                          button
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            onChange({
                              ...(data || {}),
                              assets: data?.assets.filter(
                                (l) => l.value !== asset.value,
                              ),
                            });
                          }}
                        />
                      )
                    }
                    style={{ padding: 0, maxWidth: '100%' }}
                  />
                );
              }
              if (asset.type === 'iframe') {
                return (
                  <div
                    style={{
                      width: '100%',
                      aspectRatio: '16/10',
                      position: 'relative',
                    }}
                    key={asset.id}
                    ref={assetThumb}
                  >
                    {Module.isEditing && (
                      <PinkToolbar
                        parentRef={assetThumb}
                        style={{ zIndex: 99 }}
                        {...{
                          size: 'small',
                          overlay: true,
                          floating: true,
                        }}
                      >
                        <Icon
                          key="xx"
                          name="x-lg"
                          bubble
                          button
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            onChange({
                              ...(data || {}),
                              assets: data?.assets.filter(
                                (l) => l.value !== asset.value,
                              ),
                            });
                          }}
                        />
                      </PinkToolbar>
                    )}
                    {asset?.data?.type === 'youtube' && (
                      <iframe
                        style={{
                          width: '100%',
                          height: '100%',
                          border: 0,
                          position: 'relative',
                          zIndex: 10,
                        }}
                        src={youtubeUrlToEmbedUrl(asset?.data?.url)}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      />
                    )}
                    {asset?.data?.type === 'microsoft_forms' && (
                      <iframe
                        style={{ width: '100%', height: '100%', border: 0 }}
                        src={asset?.data?.url}
                      >
                        {' '}
                      </iframe>
                    )}
                    {asset?.data?.type === 'figma' && (
                      <iframe
                        style={{ width: '100%', height: '100%', border: 0 }}
                        src={asset?.data?.url}
                        allowfullscreen
                      />
                    )}
                    {asset?.data?.type === 'storybook' && (
                      <iframe
                        style={{ width: '100%', height: '100%', border: 0 }}
                        src={asset?.data?.url}
                        allowfullscreen
                      />
                    )}
                  </div>
                );
              }
            })}
          </Flex>
        )}
        {!disable.includes('text') && (
          <div
            ref={richTextRef}
            style={{
              position: 'relative',
              // marginTop: !Module.isEditing && hasText ? 10 : 0,
            }}
          >
            {isRichTextVisible || focus.includes('text') ? (
              <>
                {!focus.includes('text') && Module.isEditing && (
                  <PinkToolbar
                    parentRef={richTextRef}
                    size="small"
                    overlay
                    style={{ zIndex: 99 }}
                  >
                    <Icon
                      name="x-lg"
                      bubble
                      button
                      onClick={() => {
                        onChange({
                          ...(data || {}),
                          text: '',
                          textFile: '',
                        });
                        setIsRichTextVisible(false);
                      }}
                    />
                  </PinkToolbar>
                )}
                <AddRichText
                  isEditing={Module.isEditing}
                  Board={Board}
                  Module={Module}
                  library={
                    userStatus.activeServices?.brand_assets
                      ? 'brandassets-texts'
                      : 'documents'
                  }
                  textFile={data?.textFile}
                  onTextFileSave={(doc) => {
                    onChange({
                      ...(data || {}),
                      text: '',
                      textFile: doc?._id
                        ? {
                            value: doc._id,
                            library: userStatus.activeServices?.brand_assets
                              ? 'brandassets-texts'
                              : 'documents',
                          }
                        : undefined,
                    });
                  }}
                  textEditorPrefs={textEditorPrefs}
                  value={data?.text}
                  placeholder={defaultText}
                  white={white}
                  key={data?.id}
                  id={data?.id}
                  onChange={(value) => {
                    onChange({
                      ...(data || {}),
                      text: value,
                    });
                  }}
                />
              </>
            ) : (
              Module.isEditing && (
                <Button
                  inline
                  className="no_global_style"
                  pixi
                  onClick={() => setIsRichTextVisible(true)}
                >
                  {' '}
                  <Icon name="plus-lg" />
                  Add text
                </Button>
              )
            )}
          </div>
        )}
        {(links?.length || downloads?.length || Module.isEditing) && (
          <Group
            justify={
              alignButtons === 'left' || !alignButtons
                ? 'flex-start'
                : alignButtons === 'center'
                  ? 'center'
                  : 'flex-end'
            }
            gap="5"
            mt="xs"
            mb={Module.isEditing && 'xs'}
          >
            <SortableV2
              items={
                links
                  ?.filter(
                    (v, i, a) =>
                      a.findIndex((i) => v.value === i.value) === i && !!v,
                  )
                  .map((link) => ({
                    data: link,
                    id: link.value,
                  })) || []
              }
              style={{ flexWrap: 'wrap', gap: 10 }}
              disabled={!Module.isEditing}
              onChange={(items) => {
                onChange({
                  ...(data || {}),
                  links: [
                    ...items.map((item) =>
                      data?.links?.find((link) => link.value === item.id),
                    ),
                  ],
                });
              }}
              render={({ data: link }, props) => (
                <Link
                  key={link.value}
                  ref={props.ref}
                  onRemove={() => {
                    onChange({
                      ...(data || {}),
                      links: data?.links.filter((l) => l.value !== link.value),
                    });
                  }}
                  type="link"
                  link={link}
                  data={data}
                  backgroundColor={buttonBackgroundColor}
                  onChange={(linkId, link) => {
                    delete link.data;
                    onChange({
                      ...(data || {}),
                      links: data?.links
                        ?.filter((l) => !!l)
                        .map((l) => (l.value === linkId ? link : l)),
                    });
                  }}
                  onClick={(event, label) => {
                    if (link.type === 'collection') {
                      openCollectionPreview(
                        link.data,
                        openCollectionPreviewPrefs,
                      );
                      analytics.trackEvent('Board button link clicked', {
                        'Board Id': Board.data?._id,
                        'Board button type': link.type,
                        'Board button label': label,
                        'Board button action': `${link.data?._id}`,
                      });
                    }
                    if (link.type === 'board') {
                      if (link?.preferences?.openInNewWindow) {
                        openInNewTab(
                          Board.openBoard?.(link.data, '', '', true),
                        );
                      }
                      Board.openBoard?.(link.data);
                      analytics.trackEvent('Board button link clicked', {
                        'Board Id': Board.data?._id,
                        'Board button type': link.type,
                        'Board button label': label,
                        'Board button action': `${link.data?._id}`,
                      });
                    }
                    if (link?.type === 'file') {
                      // fixme: for Jacob. We do not have Libraries.media.downloadFilesAsZip.
                      // fixme: Use downloadBag.downloadFiles(files, community slug, board context) instead.
                      // if (link.data.data?.type === 'font-family') {
                      //   Libraries.media.downloadFilesAsZip(link.data.data.files);
                      // }
                      downloadUrl(generateUrl(link.data?.file?.url));
                      analytics.trackEvent('Board button link clicked', {
                        'Board Id': Board.data?._id,
                        'Board button type': link.type,
                        'Board button label': label,
                        'Board button action': `${link.data?._id}`,
                      });
                    }
                    if (link.type === 'email') {
                      openInNewTab(`mailto:${link.value}`);
                      analytics.trackEvent('Board button link clicked', {
                        'Board Id': Board.data?._id,
                        'Board button type': link.type,
                        'Board button label': label,
                        'Board button action': `mailto:${link.value}`,
                      });
                    }
                    if (link.type === 'custom') {
                      const url =
                        link.value.indexOf('://') === -1
                          ? `http://${link.value}`
                          : link.value;
                      analytics.trackEvent('Board button link clicked', {
                        'Board Id': Board.data?._id,
                        'Board button type': link.type,
                        'Board button label': label,
                        'Board button action': url,
                      });
                      openLink(
                        url,
                        isEmbed &&
                          !link?.value?.includes('pickit') &&
                          !link?.value?.includes('/#/brand') &&
                          !link?.value?.includes('/#/board')
                          ? false
                          : !link?.preferences?.openInNewWindow,
                      );
                    }
                  }}
                  forceEditing={
                    newLink.id === link.value && newLink.list === 'links'
                  }
                  props={props}
                  Module={Module}
                  Board={Board}
                />
              )}
            />
            <SortableV2
              items={
                downloads
                  ?.filter(
                    (v, i, a) => a.findIndex((i) => v.value === i.value) === i,
                  )
                  ?.filter((v) => {
                    if (Module.isEditing) {
                      return true;
                    }
                    if (v?.type === 'collection' && !v?.data?._id) {
                      return false;
                    }
                    if (v?.type === 'file' && !v?.data?.file?.url) {
                      return false;
                    }
                    return true;
                  })
                  .map((link) => ({
                    data: link,
                    id: link.value,
                  })) || []
              }
              style={{ flexWrap: 'wrap' }}
              disabled={!Module.isEditing}
              onChange={(items) => {
                onChange({
                  ...(data || {}),
                  downloads: [
                    ...items.map((item) =>
                      data?.downloads?.find((link) => link.value === item.id),
                    ),
                  ],
                });
              }}
              render={({ data: link }, props) => (
                <Link
                  type="download"
                  key={link.value}
                  onRemove={() => {
                    onChange({
                      ...(data || {}),
                      downloads: data?.downloads.filter(
                        (l) => l.value !== link.value,
                      ),
                    });
                  }}
                  termsOfUse={
                    boardPreferences?.terms?.download?.active &&
                    boardPreferences?.terms?.download?.message
                  }
                  link={link}
                  data={data}
                  backgroundColor={buttonBackgroundColor}
                  onChange={(linkId, link) => {
                    delete link.data;
                    onChange({
                      ...(data || {}),
                      downloads: data?.downloads
                        ?.filter((l) => !!l)
                        .map((l) => (l.value === linkId ? link : l)),
                    });
                  }}
                  onClick={() => {
                    if (link.type === 'collection') {
                      downloadUrl(
                        `https://collection-downloader.azurewebsites.net/download/collection?collection_id=${link.data._id}&file_name=${link.data.name}&jwt=${User?.token?.jwt}&community_slug=${Board.data.communitySlug}&service=${link.data.libraryType}&context=board_${Board.data._id}`,
                      );
                    }
                    if (link?.type === 'file') {
                      downloadUrl(generateUrl(link.data?.file?.url));
                    }
                  }}
                  forceEditing={
                    newLink.id === link.value && newLink.list === 'downloads'
                  }
                  props={props}
                  Module={Module}
                />
              )}
            />
            {Module.isEditing && !disable.includes('link') && (
              <Group gap="5" align={alignButtons}>
                {!disable.includes('link') && (
                  <PixiDropdown
                    zIndex={15}
                    classNameDropdown="link_menu"
                    target={
                      <PixiButton
                        variant={white ? 'white' : 'light'}
                        color="dark"
                        className="no_global_style"
                        leftSection={<PixiIcon name="plus-large" />}
                      >
                        Add link
                      </PixiButton>
                    }
                  >
                    <LinkForm
                      position="right-start"
                      onSubmit={({ url, label, preferences }) =>
                        onChange({
                          ...(data || {}),
                          links: [
                            ...(data?.links || []),
                            {
                              type: 'custom',
                              value: url,
                              label,
                              preferences,
                            },
                          ],
                        })
                      }
                      target={
                        <PixiDropdown.Item icon="link">
                          Custom link
                        </PixiDropdown.Item>
                      }
                    />
                    <LinkForm
                      variant="email"
                      position="right-start"
                      onSubmit={({ url, label, preferences }) =>
                        onChange({
                          ...(data || {}),
                          links: [
                            ...(data?.links || []),
                            {
                              type: 'custom',
                              value: url,
                              label,
                              preferences,
                            },
                          ],
                        })
                      }
                      target={
                        <PixiDropdown.Item icon="envelope">
                          Email
                        </PixiDropdown.Item>
                      }
                    />
                    <LinkForm
                      variant="board"
                      position="right-start"
                      onSubmit={({ board, label, preferences }) =>
                        onChange({
                          ...(data || {}),
                          links: [
                            ...(data?.links || []),
                            {
                              type: 'board',
                              value: board._id,
                              label,
                              preferences,
                            },
                          ],
                        })
                      }
                      target={
                        <PixiDropdown.Item icon="envelope">
                          Board
                        </PixiDropdown.Item>
                      }
                    />
                    <LinkForm
                      variant="media_collection"
                      position="right-start"
                      onSubmit={({ collection, label, preferences }) =>
                        onChange({
                          ...(data || {}),
                          links: [
                            ...(data?.links || []),
                            {
                              type: 'collection',
                              library: 'media',
                              value: collection._id,
                              label,
                              preferences,
                            },
                          ],
                        })
                      }
                      target={
                        <PixiDropdown.Item icon="rectangle-history">
                          Media collection
                        </PixiDropdown.Item>
                      }
                    />
                    <LinkForm
                      variant="document_collection"
                      position="right-start"
                      onSubmit={({ collection, label, preferences }) =>
                        onChange({
                          ...(data || {}),
                          links: [
                            ...(data?.links || []),
                            {
                              type: 'collection',
                              library: 'documents',
                              value: collection._id,
                              label,
                              preferences,
                            },
                          ],
                        })
                      }
                      target={
                        <PixiDropdown.Item icon="rectangle-history">
                          Documents collection
                        </PixiDropdown.Item>
                      }
                    />
                  </PixiDropdown>
                )}
                {!disable.includes('download') && (
                  <PixiDropdown
                    zIndex={15}
                    classNameDropdown="link_menu"
                    target={
                      <PixiButton
                        variant={white ? 'white' : 'light'}
                        color="dark"
                        className="no_global_style"
                        leftSection={<PixiIcon name="plus-large" />}
                      >
                        Add download
                      </PixiButton>
                    }
                  >
                    {(prefs) => (
                      <>
                        <LinkForm
                          variant="file"
                          position="right-start"
                          library="media"
                          submitLabel="Add download"
                          dropdownRenderProps={prefs}
                          onSubmit={({ file, label }) =>
                            onChange({
                              ...(data || {}),
                              downloads: [
                                ...(data?.downloads || []),
                                {
                                  type: 'file',
                                  library: 'media',
                                  value: file._id,
                                  label,
                                },
                              ],
                            })
                          }
                          target={
                            <PixiDropdown.Item icon="image">
                              Media
                            </PixiDropdown.Item>
                          }
                        />
                        <LinkForm
                          variant="file"
                          position="right-start"
                          library="documents"
                          submitLabel="Add download"
                          dropdownRenderProps={prefs}
                          onSubmit={({ file, label }) => {
                            onChange({
                              ...(data || {}),
                              downloads: [
                                ...(data?.downloads || []),
                                {
                                  type: 'file',
                                  library: 'documents',
                                  value: file._id,
                                  label,
                                },
                              ],
                            });
                          }}
                          target={
                            <PixiDropdown.Item icon="file-lines">
                              Document
                            </PixiDropdown.Item>
                          }
                        />
                        {userStatus.activeServices.brand_assets && (
                          <LinkForm
                            variant="file"
                            position="right-start"
                            submitLabel="Add download"
                            dropdownRenderProps={prefs}
                            library="brandassets"
                            selectFileProps={{
                              libraryId: 'brandassets',
                              assetGridProps: {
                                forceFilters: {
                                  'data.type': [
                                    {
                                      value: ['font-family', 'text', 'color'],
                                      modifier: '$nin',
                                    },
                                  ],
                                },
                              },
                            }}
                            onSubmit={({ file, label }) => {
                              onChange({
                                ...(data || {}),
                                downloads: [
                                  ...(data?.downloads || []),
                                  {
                                    type: 'file',
                                    library: 'media',
                                    value: file._id,
                                    label,
                                  },
                                ],
                              });
                            }}
                            target={
                              <PixiDropdown.Item icon="palette">
                                Brand asset
                              </PixiDropdown.Item>
                            }
                          />
                        )}
                        <LinkForm
                          variant="media_collection"
                          position="right-start"
                          submitLabel="Add download"
                          onSubmit={({ collection, label }) =>
                            onChange({
                              ...(data || {}),
                              downloads: [
                                ...(data?.downloads || []),
                                {
                                  type: 'collection',
                                  library: 'media',
                                  value: collection._id,
                                  label,
                                },
                              ],
                            })
                          }
                          target={
                            <PixiDropdown.Item icon="rectangle-history">
                              Media collection
                            </PixiDropdown.Item>
                          }
                        />
                        <LinkForm
                          variant="document_collection"
                          position="right-start"
                          submitLabel="Add download"
                          onSubmit={({ collection, label }) =>
                            onChange({
                              ...(data || {}),
                              downloads: [
                                ...(data?.downloads || []),
                                {
                                  type: 'collection',
                                  library: 'documents',
                                  value: collection._id,
                                  label,
                                },
                              ],
                            })
                          }
                          target={
                            <PixiDropdown.Item icon="rectangle-history">
                              Documents collection
                            </PixiDropdown.Item>
                          }
                        />
                      </>
                    )}
                  </PixiDropdown>
                )}
              </Group>
            )}
          </Group>
        )}
      </div>
    </>
  );
}

export function ContentItemWrapper(props) {
  const { sortableProps, isRearrange, isOnlyOne, ...childProps } = props;
  const { ref: dragRef, ...rest } = sortableProps;
  const contentRef = useRef();
  const Boards = useBoards();
  const Libraries = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brand: useMediaContext(),
  };

  const className = ['ContentSection__blockwrapper'];

  if (isOnlyOne) {
    className.push('ContentSection__blockwrapper--isOnlyOne');
  }
  if (isRearrange) {
    className.push('ContentSection__blockwrapper--rearrange');
  }

  if (childProps?.data?.isHidden) {
    className.push('ContentSection__blockwrapper--hidden');
  }
  function filterItems(item) {
    if (!item) {
      return false;
    }
    if (item.type === 'file') {
      item.library = item.library === 'brandassets' ? 'media' : item.library;
      return !!Libraries[item.library]?.data?.files?.find(
        (file) => file._id === item.value,
      );
    }
    if (item.type === 'collection') {
      if (!item.library) {
        return false;
      }
      return !!Libraries[item.library].data.collections?.find(
        (col) => col._id === item.value,
      );
    }
    if (item.type === 'board') {
      return !!Boards.data.find((board) => board._id === item.value);
    }
    if (item.type === 'iframe') {
      return true;
    }
    return false;
  }

  const assetsExists = !!childProps?.data?.assets?.filter(filterItems)?.length;
  const linksExists = !!childProps?.data?.links?.filter(filterItems)?.length;
  const downloadsExists =
    !!childProps?.data?.dowloads?.filter(filterItems)?.length;
  const textExists =
    !!childProps?.data?.text?.length || !!childProps?.data?.textFile?.value;

  if (!assetsExists && !linksExists && !downloadsExists && !textExists) {
    className.push('ContentSection__blockwrapper--empty');
  }

  if (childProps?.data?.assets?.length) {
    // childProps.data.assets = childProps.data.assets.filter(filterItems);
  }

  return (
    <div
      className={className.join(' ')}
      {...rest.listeners}
      {...rest.attributes}
      style={rest.style}
      ref={isRearrange ? dragRef : contentRef}
    >
      <ContentItem
        isRearrange={isRearrange}
        isOnlyOne={isOnlyOne}
        {...childProps}
      />
    </div>
  );
}

export default function ContentSection({
  Module,
  Board,
  isOnlyOne,
  contentSectionKey,
  textEditorPrefs,
  defaultText,
  assetFocus,
  assetLabel,
  alignButtons,
  forceReplaceAsset,
  buttonBackgroundColor,
  boardPreferences,
  focus,
  white,
  disable,
  multipleAssets,
}) {
  const module = Module.getModule();
  const { data } = module;
  const contentData = data?.[contentSectionKey || 'contentSection']?.data || [];
  const contentBlocks = contentData;

  function chunk(arr, len) {
    const chunks = [];
    let i = 0;
    const n = arr.length;

    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }

    return chunks;
  }

  function getMissingBlocks(blocks, contentPerRow) {
    const chunks = chunk(blocks, contentPerRow);
    const lastRow = chunks?.[chunks.length - 1]?.length;
    const missingBlocks = contentPerRow - lastRow || 0;

    return missingBlocks;
  }

  function addMissingBlocks(Module, data, contentPerRow) {
    const missingBlocks = data?.length
      ? getMissingBlocks(data, contentPerRow)
      : contentPerRow;
    const newPlaceholders = [];
    for (let i = 0; i < missingBlocks; i++) {
      newPlaceholders.push({
        id: randomString(10),
        placeholder: true,
      });
    }
    Module.setData({
      [contentSectionKey || 'contentSection']: {
        ...(data?.[contentSectionKey || 'contentSection'] || {}),
        data: [...data, ...newPlaceholders],
      },
    });
  }

  const [isRearrange, setIsRearrange] = useState(false);

  useEffect(() => {
    if (
      !Module.isEditing /* || data?.[contentSectionKey || 'contentSection'] */
    ) {
      return () => {};
    }
    const missingBlocks = getMissingBlocks(
      (data?.[contentSectionKey || 'contentSection'] || {})?.data || [],
      Module.metadata?.contentSection?.contentPerRow || (isOnlyOne ? 1 : 2),
    );

    if (
      missingBlocks ||
      !data?.[contentSectionKey || 'contentSection']?.data?.length
    ) {
      addMissingBlocks(
        Module,
        (data?.[contentSectionKey || 'contentSection'] || {})?.data || [],
        Module.metadata?.contentSection?.contentPerRow || (isOnlyOne ? 1 : 2),
      );
    }
  }, [
    Module.metadata?.contentSection?.contentPerRow,
    data?.[contentSectionKey || 'contentSection']?.data,
    Module.isEditing,
  ]);

  const className = ['ContentSection__content'];

  if (Module.metadata?.contentSection?.colorNoSpace) {
    className.push('ContentSection__content--colorNoSpace');
  }

  if (white) {
    className.push('ContentSection__content--white');
  }

  function mergeAtIndex(a, b, i = 0) {
    return a.slice(0, i).concat(b, a.slice(i));
  }

  function saveMultipleData(data, key) {
    Module.setData({
      [contentSectionKey || 'contentSection']: {
        ...(data?.[contentSectionKey || 'contentSection'] || {}),
        data: mergeAtIndex(
          contentData.filter((d) => !d.placeholder),
          data,
          key,
        ),
      },
    });
  }

  function saveData(moduleId, data) {
    const currentData = contentData.find((d) => d.id === moduleId);
    Module.setData({
      [contentSectionKey || 'contentSection']: {
        ...(data?.[contentSectionKey || 'contentSection'] || {}),
        data: [
          ...contentData.map((d) => (d.id === moduleId ? { ...data } : d)),
          ...(!currentData ? [data] : []),
        ],
      },
    });
  }

  const contentPerRow =
    Module.metadata?.contentSection?.contentPerRow || (isOnlyOne ? 1 : 2);

  return (
    <div
      className="ContentSection"
      columns={
        Module.metadata?.contentSection?.contentPerRow || (isOnlyOne ? 1 : 2)
      }
    >
      {/* <div className="ContentSection__tabs">
      <Button inline><Icon name="plus-lg" /> Add tab</Button>
    </div> */}
      <div
        className={className.join(' ')}
        style={{ alignItems: Module.metadata?.contentSection?.alignItems }}
      >
        <SortableV2
          items={contentBlocks}
          disabled={!isRearrange}
          key={contentSectionKey}
          onChange={(items) => {
            Module.setData({
              [contentSectionKey || 'contentSection']: {
                ...(data?.contentSection || {}),
                data: items,
              },
            });
          }}
          render={(renderContentData, props, key) => {
            const chunks = chunk(contentBlocks, contentPerRow);
            const currentChunk =
              chunks[
                chunks.findIndex((chunk) =>
                  chunk.find((c) => c.id === renderContentData.id),
                )
              ] || [];
            const placeholdersInChunk = currentChunk.filter(
              (c) => c.placeholder,
            )?.length;
            const idsInChunk = currentChunk.map((c) => c.id);
            const onlyPlaceholdersInChunk =
              placeholdersInChunk === contentPerRow;

            return (
              <ContentItemWrapper
                data={renderContentData}
                key={renderContentData.id}
                sortableProps={props}
                isRearrange={isRearrange}
                setIsRearrange={setIsRearrange}
                isOnlyOne={isOnlyOne}
                assetFocus={assetFocus}
                assetLabel={assetLabel}
                boardPreferences={boardPreferences}
                multipleAssets={multipleAssets}
                textEditorPrefs={textEditorPrefs}
                buttonBackgroundColor={buttonBackgroundColor}
                white={white}
                forceReplaceAsset={forceReplaceAsset}
                defaultText={defaultText}
                alignButtons={Module.metadata?.alignButtons || alignButtons}
                allContentBlocks={contentData || []}
                onChangeMultiple={(newData) => {
                  saveMultipleData(
                    newData.map((item) => ({
                      ...item,
                      id: randomString(10),
                      placeholder: false,
                    })),
                    key,
                  );
                }}
                onChange={(newData) => {
                  if (
                    newData?.assets?.length ||
                    newData?.text ||
                    newData?.links?.length ||
                    newData?.downloads?.length
                  ) {
                    delete newData.placeholder;
                  } else {
                    newData.placeholder = true;
                  }
                  if (isOnlyOne || !contentBlocks.length) {
                    return Module.setData({
                      [contentSectionKey || 'contentSection']: {
                        ...(data?.[contentSectionKey || 'contentSection'] ||
                          {}),
                        data: [
                          {
                            ...newData,
                            id: newData.id || randomString(10),
                          },
                        ],
                      },
                    });
                  }
                  saveData(newData.id, newData);
                }}
                onRemove={() => {
                  Module.setData({
                    [contentSectionKey || 'contentSection']: {
                      ...(data?.[contentSectionKey || 'contentSection'] || {}),
                      data: contentBlocks.filter((r) =>
                        onlyPlaceholdersInChunk
                          ? !idsInChunk.includes(r.id)
                          : r.id !== contentData.id,
                      ),
                    },
                  });
                }}
                disableDelete={
                  key < contentPerRow ||
                  (!onlyPlaceholdersInChunk && contentData.placeholder)
                }
                focus={focus}
                disable={disable}
                Module={Module}
                Board={Board}
                metadata={Module.metadata?.contentSection}
              />
            );
          }}
        />
        {Module.isEditing && !isOnlyOne && (
          <Button
            as="div"
            onClick={() => {
              const newPlaceholders = [];
              for (let i = 0; i < contentPerRow; i++) {
                newPlaceholders.push({
                  id: randomString(10),
                  placeholder: true,
                });
              }
              Module.setData({
                [contentSectionKey || 'contentSection']: {
                  ...(data?.[contentSectionKey || 'contentSection'] || {}),
                  data: [
                    ...((data?.[contentSectionKey || 'contentSection'] || {})
                      ?.data || []),
                    ...newPlaceholders,
                  ],
                },
              });
            }}
            transparent
            style={{ marginTop: 10, height: 39 }}
            className="no_global_style"
          >
            <Icon name="plus-lg" />
            Add row
          </Button>
        )}
      </div>
    </div>
  );
}
