import { Paper } from '@mantine/core';
import PixiDropdown from './elements/Dropdown';
import AppInformationDropdown from './components/AppInformationDropdown';
import System from './System';
import PixiTooltip from './elements/Tooltip';
import { useRouter } from 'hooks/useRouter';
import { useAppUser } from './AppUserController';
import { useUserContext } from 'hooks';

export default function AppPreviewBanner() {
  const Router = useRouter();
  const User = useUserContext();
  const appUser = useAppUser();
  if (
    !appUser.user?.djangoProfile?.profile?.is_staff &&
    !window.location.origin?.includes('localhost')
  ) {
    return <></>;
  }
  return (
    <PixiDropdown
      width={400}
      openDelay={1000}
      trigger="hover"
      target={
        <Paper
          w={40}
          h={40}
          pos="fixed"
          bg="transparent"
          style={{ zIndex: 9999, pointerEvents: 'auto' }}
          bottom={0}
          p={5}
          fw="bold"
          left={0}
          fz={10}
        />
      }
      position="top"
    >
      <AppInformationDropdown />
      <PixiDropdown.Divider />
      <PixiDropdown.Label>Developer tools</PixiDropdown.Label>
      <PixiDropdown.Item
        icon="bug"
        onClick={() => {
          Router.history.push('/manage/experimental');
        }}
      >
        Experimental features
      </PixiDropdown.Item>
      <PixiTooltip label="Onboarding cards, tooltips etc.">
        <PixiDropdown.Item
          icon="circle-info"
          color="orange"
          onClick={async () => {
            await User.saveUserSettingsPartial({
              [`flags`]: {},
            });
          }}
        >
          Clear all flags
        </PixiDropdown.Item>
      </PixiTooltip>
      <PixiDropdown.Item
        icon="exclamation"
        color="red"
        onClick={async () => {
          await System.Report.logError(new Error('Fake error'));
        }}
      >
        Report fake error
      </PixiDropdown.Item>
    </PixiDropdown>
  );
}
